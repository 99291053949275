// src/About.js
import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import logo from '../myassets/logo.png';



function About() {
  return (
    <div className="about-container">

      <div className="title-header">
        <h1 >About LingoTrainer</h1>
          <Link to="/">
            <img src={logo} alt="LingoTrainer Logo" className="title-logo" />
          </Link>        
      </div>

      <p>
        LingoTrainer is an innovative language learning tool designed to help you practice and master new languages. 
        Whether you're a beginner or an advanced learner, LingoTrainer offers personalized practice sentences, word insights, and more to enhance your language learning experience.
      </p>
      <h2>Features</h2>
      <ul>
        <li>Generate practice sentences based on your selected difficulty and context.</li>
        <li>Get detailed insights on specific words, including etymology and usage.</li>
        <li>Save and track the words you’ve learned in your personal database.</li>
        <li>Download stored words and insights for offline study.</li>
      </ul>
      <p>
        Our mission is to make language learning more accessible and engaging by leveraging the power of AI.
      </p>

    {/* Navigation Links */}
    <nav>
        <Link to="/">Back</Link>
    </nav>
    </div>
  );
}

export default About;