import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Trainer from './components/Trainer';
import About from './components/About';
import QTrainer from './components/QTrainer';
import WordDisplay from './components/WordDisplay';



function App() {
  return (
    <Router>
      <div>      
        {/* Route Switch */}
        <Routes>
          {/* Home Route */}
          <Route path="/" element={<Trainer />} />
          
          {/* About Route */}
          <Route path="/About" element={<About />} />
          <Route path="/QTrainer" element={<QTrainer />} />
          <Route path="/words-display" element={<WordDisplay />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
