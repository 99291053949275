import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import { Link } from 'react-router-dom'; // Import Link for navigation
import logo from '../myassets/logo.png';




function WordsDisplay() {
  const [words, setWords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch all words from the database on component mount
  useEffect(() => {
    const fetchWords = async () => {
      try {
        const res = await axios.get('/api/get-all-words/');
        setWords(res.data.words);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch words from the database');
        setLoading(false);
      }
    };

    fetchWords();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="words-display-container">
        <div className="title-header">
          <h1>Stored Words</h1>
            <Link to="/">
              <img src={logo} alt="LingoTrainer Logo" className="title-logo" />
            </Link>
          </div>
      {/* Navigation Links */}
      <nav>
        <Link to="/">Go Back</Link>
      </nav>

      {/* Words Table */}
      <h1> </h1>
      <table className="words-table">
        <thead>
          <tr>
            <th>Word</th>
            <th>Translation</th>
            <th>Etymology</th>
          </tr>
        </thead>
        <tbody>
          {words.map((wordObj, index) => (
            <tr key={index}>
              <td><strong>{wordObj.word}</strong></td>
              <td>{wordObj.translation || 'No translation available'}</td>
              <td>{wordObj.etymology || 'No etymology available'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default WordsDisplay;
